import { Environment } from './types';

export type AppRedirectUrlOptions = {
  environment: Environment | string;
  path?: string;
  asCompany?: boolean;
  register?: boolean;
  hideTerms?: boolean;
  email?: string;
  token?: string;
  theme?: string;
  silentLogin?: boolean;
};

const baseUrlMapper: Record<Environment, string> = {
  [Environment.porky]: 'https://app.mayple.com/',
  [Environment.storky]: 'https://app.selectom.wtf/',
  [Environment.torky]: 'https://app.selectom.to/',
  [Environment.dorky]: 'http://app.selectom.rocks:3002/',
};

const getAppRedirectUrl = (options: AppRedirectUrlOptions): string => {
  const { environment, path, asCompany, hideTerms, token, register, email, theme, silentLogin = false } = options;

  const baseUrl = baseUrlMapper?.[environment as Environment] || baseUrlMapper[Environment.porky];

  const redirectUrl = new URL(silentLogin ? 'silent-login' : path || '', baseUrl);

  if (asCompany) {
    redirectUrl.searchParams.append('asCompany', '1');
  }

  if (register) {
    redirectUrl.searchParams.append('register', '1');
  }

  if (hideTerms) {
    redirectUrl.searchParams.append('hideTerms', '1');
  }

  if (email) {
    redirectUrl.searchParams.append('email', email);
  }

  if (token) {
    redirectUrl.searchParams.append('t', token);
  }

  if (theme) {
    redirectUrl.searchParams.append('theme', theme);
  }

  return redirectUrl.href;
};

export default getAppRedirectUrl;
